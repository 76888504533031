
import { db } from './config'
import { ref, get } from "firebase/database";
import moment from "moment"

export const getDevices = async (data, storageGroupId) => {
  let groupId = []
  console.log(data, storageGroupId);
  if (!data) groupId = [storageGroupId]
  else groupId = Object.keys(data.user.roles).map(key =>  key)

  let devicesIds = []
    , devices = []
    , device = await get(ref(db, 'devices'))
    , deviceResponse = await device.val()
  const group = await get(ref(db, `groups`))
  let groupResponse = await group.val()
  const deviceByGroup = await get(ref(db, `deviceByGroup`))
  let deviceByGroupResponse = await deviceByGroup.val()
  , groups = []
  groupId.forEach(id => {
    if (deviceByGroupResponse[id]) {
      groups.push(id)
      Object.keys(deviceByGroupResponse[id]).forEach(deviceId => {
        devicesIds.push({deviceId, id})
      })
    }
  })

  devicesIds.forEach(({deviceId, id}) => {
    let name = deviceResponse[deviceId].name
    if (groups.length > 1) name = `${deviceResponse[deviceId].name}-${groupResponse[id].name}`
    devices.push({
      _id: deviceId,
      name,
      humidityAvg: deviceResponse[deviceId].humidityAvg,
      humidityMin: deviceResponse[deviceId].humidityMin,
      temperatureAvg: deviceResponse[deviceId].temperatureAvg,
      temperatureMin: deviceResponse[deviceId].temperatureMin
    })
  })
  return devices
}

export const getDeviceResponse = async (filters, user, storageGroupId) => {
  let dev = ''
  if (filters.device === '') {
    let responseDevice = await getDevices(user, storageGroupId)
    if (responseDevice[0]) dev = responseDevice[0]._id
  } else {
    dev = filters.device
  }
  const deviceData = await get(ref(db, `deviceResponse/${dev}`))
  let deviceResponse = await deviceData.val()
    , dataDevice = []

  let maxTemperature = {}
    , minTemperature = {}
    , avgTemperature = {}
    , maxHumidity = {}
    , minHumidity = {}
    , avgHumidity = {}
  Object.keys(deviceResponse).forEach(key => {
    let date = moment(new Date(key * 1000)).format('YYYY-MM-DD')
      , firstDate = filters.startDate ? moment(filters.startDate).subtract(24, 'h').format('YYYY-MM-DD') : moment().subtract(24, 'h').format('YYYY-MM-DD')
      , secondDate = filters.endDate ? moment(filters.endDate).add(24, 'h').format('YYYY-MM-DD') : moment().add(24, 'h').format('YYYY-MM-DD')
    if (!maxTemperature[date]) maxTemperature[date] = 0
    if (!minTemperature[date]) minTemperature[date] = 1000
    if (!avgTemperature[date]) avgTemperature[date] = { count: 0, total: 0 }

    if (maxTemperature[date] < deviceResponse[key].t) maxTemperature[date] = deviceResponse[key].t

    if (minTemperature[date] > deviceResponse[key].t) minTemperature[date] = deviceResponse[key].t

    avgTemperature[date].total += deviceResponse[key].t
    avgTemperature[date].count += 1

    if (!maxHumidity[date]) maxHumidity[date] = 0
    if (!minHumidity[date]) minHumidity[date] = 1000
    if (!avgHumidity[date]) avgHumidity[date] = { count: 0, total: 0 }

    if (maxHumidity[date] < deviceResponse[key].h) maxHumidity[date] = deviceResponse[key].h

    if (minHumidity[date] > deviceResponse[key].h) minHumidity[date] = deviceResponse[key].h

    avgHumidity[date].total += deviceResponse[key].h
    avgHumidity[date].count += 1

    if (moment(date).isBetween(moment(firstDate), moment(secondDate))) {
      let data = {
        date: key * 1000,
        battery: deviceResponse[key].b,
        humidity: parseFloat(deviceResponse[key].h.toFixed(2)),
        temperature: parseFloat(deviceResponse[key].t.toFixed(2)),
        maxTemperature: 1,
        windSpeed: deviceResponse[key].sp || 0,
        windDirection: deviceResponse[key].wo || 0
      }
      dataDevice.push(data)
    }
  })
  
  dataDevice.map(data => {
    let date = moment(data.date).format('YYYY-MM-DD')
    data[`hour`] = moment(data.date).format('HH:mm')
    data[`day`] = date
    data[`maxTemperature`] = parseFloat(maxTemperature[date].toFixed(2))
    data[`minTemperature`] = parseFloat(minTemperature[date].toFixed(2))
    data[`avgTemperature`] = parseFloat((avgTemperature[date].total / avgTemperature[date].count).toFixed(2))
    data[`maxHumidity`] = parseFloat(maxHumidity[date].toFixed(2))
    data[`minHumidity`] = parseFloat(minHumidity[date].toFixed(2))
    data[`avgHumidity`] = parseFloat((avgHumidity[date].total / avgHumidity[date].count).toFixed(2))
  })
  return dataDevice
}

export const getAuthURL = async (authToken, groupId) => {
  if (authToken && groupId) {
    const sessions = await get(ref(db, `sessions/${groupId}`))
    let sessionsResponse = await sessions.val()
    let externalURL = false
    console.log(sessionsResponse);
    Object.keys(sessionsResponse).forEach(userId => {
      if (sessionsResponse[userId] && sessionsResponse[userId].session === `${authToken}`) {
        externalURL = true
      }
    })
    return externalURL
  }
}