import React, { useState, useEffect } from 'react'
import { useAuth } from '../../db/apiMongoDB'
import { useNavigate } from "react-router-dom"

function Login() {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    email: ''
    , password: ''
  })

  const { login } = useAuth()

  const handleChange = ({ target: { name, value } }) =>
    setUser({ ...user, [name]: value })

  const handleSubmit = async (e) => {
    e.preventDefault()
    let res = await login(user.email, user.password)
    if (res && res.user) {
      Object.keys(res.user.roles).forEach(groupId => {
        res.user.roles[groupId].find(e => {
          if (e == "sensor") navigate('/dashboard')
        })
      })
    }
  }

  useEffect(() => {
    const loggedUserJson = window.localStorage.getItem('loggedNoteAppUser')
    if (loggedUserJson && loggedUserJson !== '""') {
      navigate('/dashboard')
    }
  }, [])

  return (
    <div className="form-module">
      <div className="toggle" />
      <div className="form">
        <h2>AgroPatterns IOT</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="email" onChange={handleChange} placeholder="Correo" />
          <input type="password" name="password" onChange={handleChange} placeholder="Contraseña" />
          <button type="submit" name="submit">Iniciar Sesión</button>
        </form>
      </div>
    </div>
  )
}

export default Login