import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getDevices, getDeviceResponse, getAuthURL } from '../db/apiFirebase'
import { useAuth } from '../db/apiMongoDB'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import { CSVLink } from "react-csv";

import Line from './charts/Line'

function Dashboard() {
  const initialStateValues = {
    startDate: '',
    endDate: '',
    device: ''
  }
  const initialChart = {
    temperature: false,
    humidity: false
  }

  const [listDevices, setListDevices] = useState([])
  const [responseDevice, setResponseDevice] = useState([])
  const [authUrl, setAuthUrl] = useState() 
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  
  const { authToken, groupId} = useParams();

  const getLocalAuthURL = async (group, token) => {
    const starCountRef = await getAuthURL(token, group)
    setAuthUrl(starCountRef)
  }

  const [chart, setChart] = useState(initialChart)
  const [values, setValues] = useState(initialStateValues)

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  const getDevicesByGroup = async (groupId) => {
    const starCountRef = await getDevices(user, groupId)
    setListDevices(starCountRef)
  }

  const getDataDevice = async (filters, groupId) => {
    const starCountRef = await getDeviceResponse(filters, user, groupId)
    setResponseDevice(starCountRef)
  }

  const Logout = async () => {
    await logout()
    navigate('/')
  }

  const printTemperature = () => {
    setTimeout(() => {
      setChart({ temperature: false, humidity: false })
    }, 1000)
    setChart({ temperature: true, humidity: false })
  }

  const printHumidity = () => {
    setTimeout(() => {
      setChart({ temperature: false, humidity: false })
    }, 1000)
    setChart({ temperature: false, humidity: true })
  }

  useEffect(() => {
    getDevicesByGroup(groupId)
    getDataDevice(values, groupId)
    getLocalAuthURL(groupId, authToken)
  }, [values, user, groupId, authToken])

  let threshold = {
    humidityAvg: 0,
    humidityMin: 0,
    temperatureAvg: 0,
    temperatureMin: 0
  }
  if (listDevices.length && values.device == '') {
    threshold['humidityAvg'] = listDevices[0].humidityAvg
    threshold['humidityMin'] = listDevices[0].humidityMin
    threshold['temperatureAvg'] = listDevices[0].temperatureAvg
    threshold['temperatureMin'] = listDevices[0].temperatureMin
  } else if (listDevices.length && values.device !== '') {
    let dev = listDevices.find(e => e._id == values.device)
    threshold['humidityAvg'] = dev.humidityAvg
    threshold['humidityMin'] = dev.humidityMin
    threshold['temperatureAvg'] = dev.temperatureAvg
    threshold['temperatureMin'] = dev.temperatureMin
  }

  const headers = [
    { label: "Fecha", key: "day" },
    { label: "Hora", key: "hour" },
    { label: "Temperatura", key: "temperature" },
    { label: "Humedad", key: "humidity" },
    { label: "Temperatura Máxima", key: "maxTemperature" },
    { label: "Temperatura Minima", key: "minTemperature" },
    { label: "Humedad Máxima", key: "maxHumidity" },
    { label: "Humedad Minima", key: "minHumidity" },
    { label: "Velocidad Del Viento", key: "windSpeed" },
    { label: "Dirección del Viento", key: "windDirection" }
  ];

  responseDevice.sort(function (a, b) {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  });
  if (user || authUrl) {

    let startDate = values.startDate ? values.startDate : moment(new Date()).format('YYYY-MM-DD')
      , endDate = values.endDate ? values.endDate : moment(new Date()).format('YYYY-MM-DD')
      
    return (
      <>
        <div className='p-1'>
          <div className="options-container">
            <label>Fecha Inicial </label>
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleInputChange}
            />
            <label>Fecha Final </label>
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleInputChange}
            />
            <select
              className="custom-select"
              name='device'
              onChange={handleInputChange}
            >
              {
                listDevices.map(device => {
                  return <option key={device._id} value={device._id}>{device.name}</option>
                })
              }
            </select>
            <div className="dropdown">
              <button className="dropbtn">Descargar</button>
              <div className="dropdown-content">
                <CSVLink headers={headers} data={responseDevice} filename="AgroPatterns IOT.csv">
                  <button className="dropOption">Descargar CSV</button>
                </CSVLink>
                <button className="dropOption" onClick={() => printTemperature()}>Descargar Temperatura</button>
                <button className="dropOption" onClick={() => printHumidity()}>Descargar Humedad</button>
              </div>
            </div>
            {
              groupId ? null
                : <button className='logout'
                  onClick={() => Logout()}
                >
                  Cerrar Sesión
                </button>
            }
            
          </div>
          <div style={{ marginLeft: "90px"}}>
            <h4>Última Velocidad Registrada: {responseDevice[responseDevice.length - 1]?.windSpeed}m/s</h4>
          </div>
          <div className="container">
            <Line data={responseDevice} type={true} threshold={threshold} printChart={chart.temperature} />
            <Line data={responseDevice} threshold={threshold} printChart={chart.humidity} />
          </div>
          <div className="table-containter">
            <div className="table-card">
              <table className="table-size">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Hora</th>
                    <th scope="col">Temperatura</th>
                    <th scope="col">Humedad</th>
                    <th scope="col">Temperatura Máxima</th>
                    <th scope="col">Temperatura Mínima</th>
                    <th scope="col">Humedad Máxima</th>
                    <th scope="col">Humedad Mínima</th>
                    <th scope="col">Velocidad Del Viento</th>
                    <th scope="col">Dirección del Viento</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    responseDevice.map((data, i) => {
                      return <tr key={i}>
                        <td key={data.date}>{moment(data.day).format('YYYY-MM-DD')}</td>
                        <td key={data.hour}>{data.hour}</td>
                        <td key={`${i}-${data.temperature}`}>{`${data.temperature} °`}</td>
                        <td>{`${data.humidity} %`}</td>
                        <td>{`${data.maxTemperature} °`}</td>
                        <td>{`${data.minTemperature} °`}</td>
                        <td>{`${data.maxHumidity} %`}</td>
                        <td>{`${data.minHumidity} %`}</td>
                        <td>{`${data.windSpeed} m/s`}</td>
                        <td>{`${data.windDirection} °`}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          {/*
      */}
        </div>
      </>
    )
  } else {
    return <>
      <div className="hit-the-floor">404</div>
      <div className="alternative-versions absolute bottom-0 w-full flex justify-center underline space-x-4 p-12">
        <a className="btn btn-success"
          style={{ marginLeft: '47%' }}
          onClick={() => Logout()}
        >
          Iniciar Sesión?
        </a>
      </div>
    </>
  }
}

export default Dashboard